<template>
  <v-form ref="form">
    <v-card>
      <!-- start of toolbar 1 -->
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>
          <span v-show="docCreate">Create</span>
          <span v-show="!docCreate">Edit</span>
          {{ objectName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          color="red"
          icon
          @click="openClosePageConfirmationDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 1 -->

      <!-- start of toolbar 2 -->
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          @click="sendData"
          :loading="$store.state.loader"
        >
          <v-icon
            left
            dark
          >mdi-upload</v-icon>Submit
        </v-btn>

        <v-spacer></v-spacer>
      </v-toolbar>
      <!-- end of toolbar 2 -->
      <v-card-text>
        <v-row dense>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              required
              label="Customer Code"
              v-model="customer"
              :append-icon="'mdi-airballoon'"
              @click:append="openBp"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="Customer Name"
              v-model="record.custmrName"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.contctCode"
              :items="dimensions"
              item-text="ContLocal"
              item-value="id"
              label="Contact Person"
            ></v-autocomplete>
          </v-col>

          <v-col cols="3">
            <v-text-field
              outlined
              dense
              required
              v-model="record.Telephone"
              label="Telephone No."
            ></v-text-field>
          </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              required
              label="Chasis No."
              v-model="record.manufSN"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="Registration No."
              v-model="record.internalSN"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              outlined
              dense
              required
              label="Item Code"
              v-model="record.itemCode"
              :append-icon="'mdi-airballoon'"
              @click:append="openItemModal()"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              outlined
              dense
              filled
              readonly
              label="Item Description"
              v-model="record.itemName"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.status"
              :items="callStatus"
              item-text="Name"
              item-value="value"
              label="Status"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-tabs
          background-color="accent"
          color="white"
        >
          <v-tab dark>Address</v-tab>

          <!--- FIRST TAB -->
          <v-tab-item>
            <v-row dense>
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  readonly
                  filled
                  label="External Reference"
                  v-model="record.ExtRef"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>

          <!--- SECOND TAB -->
          <v-tab-item>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="record.descrption"
                  document_lines="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-tab-item>

          <!--- THIRD TAB -->
          <v-tab-item>
            <!-- <v-row> Solutions </v-row> -->
            <solutions-editor
              @solution="setSolutions"
              :initial="initialHeaderRecord"
            >
            </solutions-editor>
          </v-tab-item>

          <!--- FOURTH TAB -->
          <v-tab-item>
            <!-- <v-data-table
              dense
              :headers="reldocheaders"
              :items="reldocs"
              item-key="name"
              class="elevation-1"
            ></v-data-table> -->
            <related-documents-editor
              :initial="initialHeaderRecord"
              :docCreate="docCreate"
            >
            </related-documents-editor>
          </v-tab-item>

          <!--- FIRTH TAB -->
          <v-tab-item>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="record.resolution"
                  document_lines="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-tab-item>

          <!--- FIFTH TAB -->
          <v-tab-item>
            <v-row>
              <attachment-editor
                @attachmentsSelected="setAttchments"
                :initialHeaderRecord="initialHeaderRecord"
              ></attachment-editor>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>

    <!-- start of bp modal -->
    <v-dialog
      v-model="bpDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="bpDialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>Select Customer
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="bpDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search Customers Using Card Code or Customer Name"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clicked"
                  :items-per-page="itemsPerPage"
                  v-model="selected"
                  :headers="bpHeaders"
                  :items="bpMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  hide-default-footer
                  class="elevation-1"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of bp modal-->

    <!-- one item selection modal -->
    <v-dialog
      v-model="itemDialog"
      width="950"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>List of Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.native="itemDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchItem"
                    append-icon="mdi-magnify"
                    label="Select using Item Code or Item Name"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedItems"
                  :headers="itemMasterHeaders"
                  :items="itemMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  :loading="loading"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item modal -->

    <!-- start of  Manufacture Serial modal -->
    <v-dialog
      v-model="manufactureSerialModal"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="manufactureSerialModal = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>
            List Of Customer Equipment Card
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="manufactureSerialModal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">

                <v-data-table
                  v-model="selectedEquipmentCard"
                  :items-per-page="itemsPerPage"
                  :headers="equipmentCardHeaders"
                  :items="equipmentData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  hide-default-footer
                  class="elevation-1"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of Manufacture Serial modal-->

  </v-form>
</template>


<script>
import AttachmentEditor from "./attachment-editor.vue";
import SolutionsEditor from "./solutions-editor.vue";
import RelatedDocumentsEditor from "./related-documents-editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    AttachmentEditor,
    SolutionsEditor,
    RelatedDocumentsEditor,
  },
  computed: {
    CurrentlyLoginUser() {
      return this.$store.state.user;
    },
    allUsersData() {
      return this.$store.state.userdata;
    },
    ...mapGetters("ohem", ["ohems"]),
  },
  props: {
    originNo: {
      type: String,
    },
    originName: {
      type: String,
    },
    docCreate: {
      type: Boolean,
    },
    docCopyTo: {
      type: Boolean,
    },
    objectName: {
      type: String,
    },
    initial: {
      type: Object,
      required: true,
    },
    seriesData: {
      type: Array,
    },
    defaultSeries: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    drivers: [],
    dimensions: [],
    itemGroups: [],
    attachments: [],
    origins: [],
    technicians: [],
    problem_types: [],
    pro_sub_types: [],
    initialHeaderRecord: {},

    bpMasterData: [],
    selected: [],
    itemMasterData: [],
    selectedItems: [],
    equipmentData: [],
    selectedEquipmentCard: [],
    olct: {},
    singleSelect: true,
    enabled: false,
    bpDialog: false,
    itemDialog: false,
    manufactureSerialModal: false,
    internalSerialModal: false,
    loading: false,
    search: "",
    itemsPerPage: 10,
    searchItem: null,
    Series: null,
    DocNum: null,
    activeSeries: null,
    customer: null,
    reldocheaders: [
      {
        text: "Document Type",
        align: "start",
        sortable: false,
        value: "solnID",
      },
      { text: "Document No", value: "#" },
      { text: "Date", value: "#" },
      { text: "Item No.", value: "#" },
      { text: "Item Description", value: "#" },
      { text: "Quantity", value: "#" },
      { text: "Fro Whse", value: "#" },
      { text: "To Whse", value: "#" },
    ],
    bpHeaders: [
      { text: "Code", value: "CardCode" },
      { text: "Name", value: "CardName" },
    ],
    itemMasterHeaders: [
      { text: "Item Code", value: "ItemCode" },
      { text: "Item Description", value: "ItemName" },
      { text: "In Stock", value: "OnHand" },
    ],
    equipmentCardHeaders: [
      { text: "Serial Number", value: "internalSN" },
      { text: "Item Code", value: "itemCode" },
      { text: "Item Description", value: "itemName" },
      { text: "Mfs Serial No.", value: "manufSN" },
      { text: "Business Partner Name", value: "customer" },
      { text: "Status", value: "status" },
    ],
    priorityCalls: [
      { name: "Low", value: "L" },
      { name: "Medium", value: "M" },
      { name: "High", value: "H" },
    ],
    callStatus: [
      { Name: "Open", value: -3 },
      { Name: "Pending", value: -2 },
      { Name: "Closed", value: -1 },
    ],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
    search: {
      handler: "getBpMaster",
      immediate: true,
    },
    searchItem: {
      handler: "searchItemMasterData",
      immediate: true,
    },
    defaultSeries: {
      handler: "setSeries",
      immediate: true,
    },
    customer: {
      handler: "getCustomerCardEquipmentCards",
      immediate: true,
    },
    selectedEquipmentCard: {
      handler: "equipmentCardSelected",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      if (val) {
        this.customer = val.customer;
        this.initialHeaderRecord = { ...val };
        this.record = { ...val };
      }
    },
    setSeries(val) {
      if (val) {
        if (val.NextNumber) {
          this.record.DocNum = val.NextNumber;
          this.DocNum = val.NextNumber;
          this.record.Series = val.id;
          this.activeSeries = val.id;
          this.Series = val.id;
        }

        if (val.IsManual == "Y") {
          this.docReadonly = "N";
        } else {
          this.docReadonly = "Y";
        }
      }
    },

    sendData() {
      this.$emit("data", this.record);
    },
    //

    openClosePageConfirmationDialog() {
      this.$router.push(`/equipment-card`);
    },
    setAttchments(data) {
      this.attachments = data;
    },

    setClosingDate(data) {
      if (data) {
        this.record.closedDate = data;
      }
    },

    clicked($event) {
      const selectedTenant = $event.item;

      this.customer = selectedTenant.CardCode;
      const details = {
        customer: selectedTenant.CardCode,
        custmrName: selectedTenant.CardName,
        Telephone: selectedTenant.Phone1,
      };

      const original = this.record;
      this.record = { ...original, ...details };
      this.bpDialog = false;
    },

    clickedItem($event) {
      const selectedItem = $event.item;

      const details = {
        itemCode: selectedItem.ItemCode,
        itemName: selectedItem.ItemName,
      };

      const original = this.record;
      this.record = { ...original, ...details };
      this.itemDialog = false;
    },

    getBpMaster() {
      const self = this;
      this.$store
        .dispatch("get", `/getCustomers?f=${this.search}`)
        .then((res) => {
          self.bpMasterData = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    searchItemMasterData(val) {
      if (!val) {
        return;
      }

      this.fetchEntriesDebounced();
    },
    fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.getItemMaster();
      }, 500);
    },
    getItemMaster() {
      this.loading = true;
      const self = this;
      this.$store
        .dispatch("get", `/item_masterdata?f=${this.searchItem}`)
        .then((res) => {
          self.itemMasterData = res.ResponseData;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      this.loading = false;
    },

    /**
     * MODAL OPENING
     */
    openBp() {
      if (this.docCreate) {
        this.selected = [];
        this.bpDialog = true;
      }
    },
    openItemModal() {
      this.itemDialog = true;
    },
    openManuSerialModal() {
      this.selectedEquipmentCard = [];
      this.manufactureSerialModal = true;
    },
    openInternalSerialModal() {},
    setSolutions(data) {
      this.record.solutions = data;
    },
    setDefaultData() {
      const date = new Date().toISOString().substr(0, 10);

      if (this.docCreate) {
        const details = {
          status: -3,
          priority: "L",
          createDate: date,
          assignee: this.CurrentlyLoginUser.id,
        };
        const original = this.record;
        this.record = { ...original, ...details };
      }
    },
    setDocNumber(id) {
      var seriesRecord = this.seriesData.find((record) => {
        return record.id === id;
      });

      if (seriesRecord.IsManual === "Y") {
        this.docReadonly = "N";
        this.record = { ...this.record, DocNum: null };
      } else {
        this.docReadonly = "Y";
        this.record.Series = id;
        this.DocNum = seriesRecord.NextNumber;
        this.record = { ...this.record, DocNum: seriesRecord.NextNumber };
      }
      this.activeSeries = seriesRecord.id;
    },
    getCustomerCardEquipmentCards(data) {
      if (data) {
        const self = this;
        this.$store
          .dispatch("get", `/customer-equipment-cards/${data}`)
          .then((res) => {
            self.equipmentData = res.ResponseData;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    equipmentCardSelected(val) {
      if (val.length <= 0) {
        return;
      }
      if (val) {
        var itemSelected = val[0];
        const details = {
          itemCode: itemSelected.itemCode,
          itemName: itemSelected.itemName,
          internalSN: itemSelected.internalSN,
          manufSN: itemSelected.manufSN,
        };

        const original = this.record;
        this.record = { ...original, ...details };
        this.manufactureSerialModal = false;
      }
    },
  },

  created() {
    this.setDefaultData();
  },
};
</script>