<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        height="50"
      >
        <v-toolbar-title>Related Documents</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          small
          v-show="!docCreate"
          @click="openReldocsDialog"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>Details
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <!-- start of defaults list -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="reletadDocsData"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <!-- end of defaults table list -->
      </v-card-text>
    </v-card>

    <!-- START OF RELATED DOCUMENTS MODAL -->
    <v-dialog
      v-model="reldocDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dense
          dark
          color="modalToolbar"
        >
          <v-toolbar-title>Service Call Related Document Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.native="reldocDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>

            <v-card>
              <v-row>
                <v-col cols="12">
                  <v-toolbar
                    color="secondary"
                    flat
                    height="50"
                  >
                    <v-toolbar-title>Inventory Items</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="accent"
                      small
                      @click="openDocumentTypeDialog"
                    >
                      <v-icon
                        left
                        dark
                      >mdi-plus</v-icon>New Document
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <!-- start of defaults list -->
                    <v-data-table
                      hide-default-footer
                      :headers="inventoryItemsHeaders"
                      :items="inventoryItems"
                    >
                    </v-data-table>
                    <!-- end of defaults table list -->
                  </v-card-text>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-toolbar
                    color="secondary"
                    flat
                    height="50"
                  >
                    <v-toolbar-title>Non-Inventory Items, Labor and Travel</v-toolbar-title>
                    <v-spacer></v-spacer>

                  </v-toolbar>
                  <v-card-text>
                    <!-- start of defaults list -->
                    <v-data-table
                      hide-default-footer
                      :headers="inventoryItemsHeaders"
                      :items="nonInventoryItems"
                    >
                    </v-data-table>
                    <!-- end of defaults table list -->
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>

          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--- END RELATED DOCUMENTS MODAL -->

    <!-- START OF DOCUMENT TYPE MODAL -->

    <v-dialog
      v-model="documentTypeDialog"
      width="950"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>Document Type</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.native="documentTypeDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">

                <v-data-table
                  :headers="documentTypeheaders"
                  :items="documentTypeData"
                  hide-default-footer
                >

                  <template v-slot:item.approval="{ item }">
                    <v-btn
                      class="btnLink"
                      color="accent"
                      text
                      @click="createNewDocument(item)"
                    >Add New</v-btn>
                  </template>

                  <!-- end -->
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- END OF DOCUMENT TYPE MODAL -->

  </v-container>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    docCreate: {
      type: Boolean,
    },
  },
  data: () => ({
    search: "",
    record: {},
    reletadDocsData: [],
    inventoryItems: [],
    nonInventoryItems: [],
    documentTypeData: [],
    reldocDialog: false,
    documentTypeDialog: false,
    serviceCallId: null,
    cardCode: null,
    headers: [
      { text: "Document Type", value: "objecttype.DocumentName" },
      { text: "Document No", value: "DocNumber" },
      { text: "Date", value: "DocPstDate" },
      { text: "Item No", value: "#" },
      { text: "Item Description", value: "#" },
      { text: "Quantity", value: "#" },
      { text: "From Whse", value: "#" },
      { text: "To Whse", value: "#" },
    ],
    inventoryItemsHeaders: [
      { text: "Item No", value: "ItemCode" },
      { text: "Item Name", value: "ItemName" },
      { text: "Transfer To Tech.", value: "TransToTec" },
      { text: "Delivered", value: "Delivered" },
      { text: "Returned From Tech", value: "RetFromTec" },
      { text: "Returned", value: "Returned" },
      { text: "Bill", value: "Bill" },
      { text: "Invoice Qty", value: "QtyToInv" },
    ],
    documentTypeheaders: [
      { text: "Document", value: "DocumentName" },
      { text: "Action.", value: "approval" },
    ],
  }),

  watch: {
    "$route.params.id": {
      handler: "setServiceCallId",
      immediate: true,
    },
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    setServiceCallId(val) {
      this.serviceCallId = val;
    },
    init(val) {
      this.cardCode = val.customer;
      this.inventoryItems = val.scl2;
      this.reletadDocsData = val.scl4;
      // this.reletadDocsData = val.solutions;
    },
    getData() {
      const self = this;
      this.$store
        .dispatch("get", `/cost-centers`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.userDefaults = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    removeReldoc(item) {
      const dataItem = item;
      const currentFile = this.reldocs.find((record) => {
        return record.id === dataItem.id;
      });

      const pos = this.reldocs.indexOf(currentFile);
      if (pos === -1) {
        return;
      }
      this.reldocs.splice(pos, 1);
    },
    openReldocModal() {
      this.newReldoc = null;
      this.reldocDialog = true;
    },
    addNewFile() {
      const self = this;
      const files = this.newReldoc;
      var allReldocs = self.reldocs || [];
      files.forEach((file) => {
        const newItem = {
          id: allReldocs.length + 1,
          FileName: file.name,
          reldocDetails: file,
        };
        allReldocs.push(newItem);
      });
      this.reldocs = allReldocs;
      this.reldocDialog = false;
    },
    setFiles() {
      this.$emit("reldocsSelected", this.reldocs);
    },
    setInitial(val) {
      this.reldocs = val.oats;
    },
    downloadFile(item) {
      window.open(item.realPath, "_blank");
    },
    openReldocsDialog() {
      this.reldocDialog = true;
    },
    openDocumentTypeDialog() {
      this.documentTypeDialog = true;
    },
    createNewDocument(item) {
      this.$router.push(
        `/sales/doc/create/${item.ObjectID}?serviceCallId=${this.serviceCallId}&c=${this.cardCode}`
      );
    },
    getDocumentType() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/doc_model?isDoc=1&Module=Service`)
        .then((res) => {
          self.documentTypeData = res;
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
  },
  created() {
    this.getDocumentType();
  },
};
</script>